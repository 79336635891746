import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'staff';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-employee/get-all-staffs',
  getOne: '/api-employee/get-staff/',
  create: '/api-employee/add-staff',
  update: '/api-employee/update-staff/',
  delete: '/api-employee/delete-staff/',
};

const initialState = {
  data: [],
  loading: false,
  currentData: null,
  openDialog: false,
  staffID: null,
  createLoading: false,
  updateLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCreateLoading: (state, action) => {
      state.createLoading = action.payload;
    },
    setUpdateLoading: (state, action) => {
      state.updateLoading = action.payload;
    },
    setDeleteLoading: (state, action) => {
      state.deleteLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload;
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setStaffID: (state, action) => {
      state.staffID = action.payload;
    },
  },
});

const fetchAllStaffs = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({ ...item, id: item?.employee_id }))
      )
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneStaff = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.get(apis.getOne + id);
    dispatch(slice.actions.setCurrentData(res?.data));
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createStaff = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCreateLoading(true));
    await axios.post(apis.create, data);
    await dispatch(fetchAllStaffs());
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setCreateLoading(false));
  }
};

const updateStaff = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setUpdateLoading(true));
    await axios.put(apis.update + id, data);
    // dispatch(fetchAllStaffs());
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setUpdateLoading(false));
  }
};

const deleteStaff = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDeleteLoading(true));
    await axios.delete(apis.delete + id);
    dispatch(fetchAllStaffs());
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setDeleteLoading(false));
  }
};
const promiseActions = {
  fetchAllStaffs,
  getOneStaff,
  createStaff,
  updateStaff,
  deleteStaff,
};
// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };

import PropTypes from 'prop-types';
import { Grid, Card, CardMedia, CardContent, Button, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Iconify from '../../iconify';

MultiFilePreviewGrid.propTypes = {
  files: PropTypes.array, // Can now handle both `File` objects and `image_data` objects
  onRemove: PropTypes.func,
};

export default function MultiFilePreviewGrid({ files, onRemove }) {
  const handlePreview = (file) => {
    // If the file is an object from `image_data`, use the `file_display` or `file_thumbnail`
    const previewUrl =
      file instanceof File ? URL.createObjectURL(file) : file.file_display || file.file_thumbnail;
    window.open(previewUrl, '_blank');
  };

  return (
    <Grid container spacing={2}>
      {files.map((file, index) => {
        // Handle both `File` objects and `image_data` objects
        const imageUrl =
          file instanceof File
            ? URL.createObjectURL(file)
            : file.file_thumbnail || file.file_display;

        return (
          <Grid item xs={12} sm={6} md={6} key={index}>
            <Card>
              <CardMedia
                component="img"
                height="140"
                image={imageUrl}
                alt={file.name || file.file_original_name || 'Image preview'}
                sx={{
                  height: 240, // Set height to 240px
                  width: 240, // Set width to 240px
                  objectFit: 'cover', // Ensure the image covers the area
                  margin: '0 auto', // Center horizontally
                }}
              />
              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon="mdi:eye" />}
                    onClick={() => handlePreview(file)}
                  >
                    <Typography variant="body2"> Show </Typography>
                  </Button>

                  {/* Delete Button */}
                  <Button variant="contained" color="error" onClick={() => onRemove(file)}>
                    <Iconify icon="mdi:delete" />
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}

import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'nurseList';
const depthPath = 'managements.';

const apis = {
  getOne: '/api-employee/get-nurse/',
  getAll: '/api-employee/get-all-nurses',
  create: '/api-employee/add-nurse',
  update: '/api-employee/update-nurse/',
  delete: '/api-employee/delete-nurse/',
};

const slice = createSlice({
  name,
  initialState: {
    data: [],
    loading: false,
    detailLoading: false,
    currentEditData: null,
    openCreateDialog: false,
    nurseId: null,
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setDetailLoading: (state, action) => {
      state.detailLoading = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
    setOpenCreateDialog: (state, action) => {
      state.openCreateDialog = action.payload;
    },
    setCurrentEditData: (state, action) => {
      state.currentEditData = action.payload;
    },
    setNurseID: (state, action) => {
      state.nurseId = action.payload;
    },
  },
});

const fetchAllNurses = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(
      slice.actions.setData(
        response.data?.data?.map((item) => ({ ...item, id: item?.employee_id }))
      )
    );
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const getOneData = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.get(apis.getOne + id);
    dispatch(slice.actions.setCurrentEditData(response.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const createNurse = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.post(apis.create, data);
    dispatch(fetchAllNurses());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateNurse = (data, id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setDetailLoading(true));
    await axios.put(apis.update + id, data);
    // dispatch(fetchAllNurses());
  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setDetailLoading(false));
  }
};

const deleteNurse = (id) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    await axios.delete(apis.delete + id);
    dispatch(fetchAllNurses());
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchAllNurses,
  getOneData,
  createNurse,
  updateNurse,
  deleteNurse,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };

import React, { lazy } from 'react';
import { LoadableDialog } from 'routes/elements';

/* ============ Edit Dialogs ============ */
const EditVisitListDialog = LoadableDialog(lazy(() => import('./visit-list/edit')));
const EditPatientDialog = LoadableDialog(lazy(() => import('./patient-list/edit')));
const EditAppointmentDialog = LoadableDialog(lazy(() => import('./appointment/edit')));

/* ============ Create Dialogs ============ */
const CreateAppointmentDialog = LoadableDialog(lazy(() => import('./appointment/create')));
const CreatePatientDialog = LoadableDialog(lazy(() => import('./patient-list/create')));
const AddNewPatientDialog = LoadableDialog(lazy(() => import('./visit-list/add-new-patient')));
const AddFromPatientListDialog = LoadableDialog(
  lazy(() => import('./visit-list/add-from-patient-list'))
);

/* ============ Detail Dialogs ============ */
const PatientInfoDetail = LoadableDialog(lazy(() => import('./visit-list/patient-info')));
const PatientListDetail = LoadableDialog(lazy(() => import('./patient-list/patient-info')));
const ReviewDataDialog = LoadableDialog(
  lazy(() => import('./visit-list/patient-info/features/TREATMENT_PLAN/review-data'))
);

/* ============ Detail Dialogs ============ */
const DoctorDetailDialog = LoadableDialog(lazy(() => import('./doctor-list/doctor-detail')));
const NurseDetailDialog = LoadableDialog(lazy(() => import('./nurse-list/nurse-detail')));
const StaffDetailDialog = LoadableDialog(lazy(() => import('./staff-list/staff-detail')));

// ============ Template Dialogs ============
const TemplateDialog = LoadableDialog(lazy(() => import('../template-dialog')));

// ============ New Registration Dialogs ============
const NewRegistrationDialog = LoadableDialog(
  lazy(() => import('./new-registration/add-new-from-patient-list'))
);

// ============ IPD Dialogs ============
const IpdInfoDialog = LoadableDialog(lazy(() => import('./ipd-info')));

export default function DialogContainer() {
  return (
    <div>
      {/* Edit Dialogs */}
      <EditVisitListDialog />
      {/* BUG IF OPEN */}
      <EditAppointmentDialog />
      <EditPatientDialog />

      {/* Create Dialogs */}
      <CreateAppointmentDialog />
      <AddNewPatientDialog />
      <AddFromPatientListDialog />
      <CreatePatientDialog />

      {/* Detail Dialogs */}
      <PatientInfoDetail />
      <PatientListDetail />
      {/* <ReviewDataDialog /> */}

      {/* employee */}
      <DoctorDetailDialog />
      <NurseDetailDialog />
      <StaffDetailDialog />

      {/* Template Dialogs */}
      <TemplateDialog />

      {/* New Registration */}
      <NewRegistrationDialog />

      {/* IPD info */}
      <IpdInfoDialog />
    </div>
  );
}

import axios from 'axios';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from 'utils/error';

const name = 'medicationReportSlices';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-report/get-medication-report',
};

const initialState = {
  data: [],
  subData: [],
  table1_data: [],
  table2_data: [],
  table3_data: [],
  table4_data: [],
  table5_data: [],
  table6_data: [],
  table7_data: [],
  loading: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setSubData: (state, action) => {
      state.subData = action.payload;
    },
    setTable1Data: (state, action) => {
      state.table1_data = action.payload;
    },
    setTable2Data: (state, action) => {
      state.table2_data = action.payload;
    },
    setTable3Data: (state, action) => {
      state.table3_data = action.payload;
    },
    setTable4Data: (state, action) => {
      state.table4_data = action.payload;
    },
    setTable5Data: (state, action) => {
      state.table5_data = action.payload;
    },
    setTable6Data: (state, action) => {
      state.table6_data = action.payload;
    },
    setTable7Data: (state, action) => {
      state.table7_data = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllMedicationsReport = (data) => async (dispatch) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const res = await axios.post(apis.getAll, data);
    console.log(res?.data);
    dispatch(slice.actions.setData(res?.data?.data));
    dispatch(slice.actions.setSubData(res?.data?.sub_data));


    dispatch(slice.actions.setTable1Data(res?.data?.table1_data));
    dispatch(slice.actions.setTable2Data(res?.data?.table2_data));
    dispatch(slice.actions.setTable3Data(res?.data?.table3_data));
    dispatch(slice.actions.setTable4Data(res?.data?.table4_data));
    dispatch(slice.actions.setTable5Data(res?.data?.table5_data));
    dispatch(slice.actions.setTable6Data(res?.data?.table6_data));
    dispatch(slice.actions.setTable7Data(res?.data?.table7_data));

  } catch (err) {
    dispatch(slice.actions.setError(err));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const promiseActions = {
  fetchAllMedicationsReport,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };

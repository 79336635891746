import axios from 'axios';
import { get } from 'lodash';

import { useSelector } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import { showError } from '../../../utils/error';

const name = 'departmentList';
const depthPath = 'managements.';

const apis = {
  getAll: '/api-system-setting/get-all-departments',
  add: '/api-system-setting/add-department',
  delete: '/api-system-setting/delete-department',
  update: '/api-system-setting/edit-department',
};

const initialState = {
  data: [],
  loading: false,
  editLoading: false,
  openDrawer: {
    add: false,
    detail: false,
    delete: false,
  },
  current: {
    data: null,
    loading: false,
  },
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setEditLoading: (state, action) => {
      state.editLoading = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setOpenDrawer: (state, action) => {
      const { target, value } = action.payload;
      state.openDrawer[target] = value;
    },
    setCurrentData: (state, action) => {
      state.current.data = action.payload;
    },
    setError: (state, action) => {
      showError(action.payload);
    },
  },
});

const fetchAllDepartmentList = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.getAll, data);
    dispatch(slice.actions.setData(response.data?.data));
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};
const createDepartment = (data) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setLoading(true));
    const response = await axios.post(apis.add, data);
    if (response.status === 200) {
      dispatch(slice.actions.setData(response.data?.data));
      dispatch(slice.actions.setLoading(false));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setLoading(false));
  }
};

const updateDepartment = (data, id) => async (dispatch, getState) => {
  try {
    dispatch(slice.actions.setEditLoading(true));
    const response = await axios.put(`${apis.update}/${id}`, data);
    if (response.status === 200) {
      dispatch(fetchAllDepartmentList());
      dispatch(slice.actions.setEditLoading(false));
    }
  } catch (error) {
    dispatch(slice.actions.setError(error));
  } finally {
    dispatch(slice.actions.setEditLoading(false));
  }
};

const deleteDepartment = (data) => async (dispatch) => {
  console.log(data);
  try {
    const response = await axios.delete(`${apis.delete}/${data}`);
  } catch (error) {
    dispatch(slice.actions.setError(error));
  }
};

const promiseActions = {
  fetchAllDepartmentList,
  createDepartment,
  updateDepartment,
  deleteDepartment,
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const selector = () => useSelector((state) => get(state, `${depthPath}${name}`));
const reducer = { [name]: slice.reducer };

export default { ...slice, promiseActions, selector, reducer, name };
